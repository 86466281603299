import React from 'react';
import {MDXProvider} from '@mdx-js/react';
import {MDXRenderer} from 'gatsby-plugin-mdx';

import MDXComponents from 'src/components/mdx-components';
import {Section} from 'src/components/molecules/Section';
import {Grid, Column} from 'src/components/molecules/Grid';
import {Card} from 'src/components/atoms/Card';
import {InlineList} from 'src/components/molecules/InlineList';
import {FluidImg} from 'src/embeds/image/image-dato';
import {Link} from "gatsby";
import styled, {css} from "styled-components";

const ReadMoreLink = styled.p`
  margin: 0;
  margin-top: auto;
  font-weight: 500;
  font-size: var(--fz);
  --fz: 18px;
  color: #5a16ee;
`

const MonoCategory = styled.div`
    p {
        margin: 0;
        font-size: 14px;
    }
`

const ColumnOrder = styled(Column)`
    @media (max-width: 799px) {
        order: 1;
    }
    ${({order}) =>
    order &&
    css`
      order: ${order};
    `}
`


export default function Drawer({callouts, ctas, content, heading, customData, ...rest}) {
    callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
    customData = JSON.parse(customData);

    return (
        <MDXProvider components={MDXComponents}>
            <Section {...customData?.flags}>
                <h2>{heading}</h2>
                <p style={{whiteSpace: 'pre-line'}}>{content}</p>
                {callouts.map((callout, i) => {
                    const customData = JSON.parse(callout.customData);
                    return (
                        <Card as="article" key={i}>
                            <Grid columns="repeat(2, minmax(0,1fr))" verticalAlign="center">
                                <ColumnOrder order={i % 2}>
                                    <MonoCategory className="f-mono"
                                                  dangerouslySetInnerHTML={{__html: callout.preContentNode.childMarkdownRemark.html}}/>
                                    {!!callout.heading && <h2>{callout.heading}</h2>}
                                    {callout.content && <MDXRenderer>{callout.contentNode.childMdx.body}</MDXRenderer>}
                                    {callout.ctas?.length > 0 && (
                                        <InlineList style={{width: '100%'}}>
                                            {callout.ctas?.map((cta, i) => {
                                                return (
                                                    <li key={i}>
                                                        <Link to={cta.url}>
                                                            <ReadMoreLink>
                                                                {cta.text}
                                                            </ReadMoreLink>
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </InlineList>
                                    )}
                                </ColumnOrder>
                                <Column>
                                    {callout.media ? <FluidImg data={callout.media}/> :
                                        <div>image should be there</div>}
                                </Column>
                            </Grid>
                        </Card>
                    );
                })}
            </Section>
        </MDXProvider>
    );
}
